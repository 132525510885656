import { useStaticQuery, graphql } from "gatsby";
import resolveImage from "@dataResolvers/resolveImage";

const query = graphql`
  query Error404Query {
    craft {
      globalSet(handle: "error404") {
        ... on Craft_error404_GlobalSet {
          # hero
          copy: copy0
          button: link0 {
            url
            text
          }
          otherButton: link1 {
            url
            text
          }
          image: image0 {
            ...ImageFragment
          }
        }
      }
    }
  }
`;

const dataResolver = ({ globalSet }) => {
  const { copy, button, otherButton, image } = globalSet;
  return {
    error: { copy, image: resolveImage(image), button, otherButton },
  };
};

const useDefaultsData = () => {
  const { craft: data } = useStaticQuery(query);
  return dataResolver(data);
};

export default useDefaultsData;
